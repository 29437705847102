import '../App.css';
import GamePageHeaderImage from "./pageutils";
import Screenshot from '../images/youdoit.png';

function YouDoItThen(){
    return (
        <div>
            <GamePageHeaderImage image={Screenshot} position="bottom 75%" objectFit="contain" alt="Screenshot from You Do It Then featuring one of the characters gesturing at a desk"/>
            <div className="GamePageBody">
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/bgnHvab1Mog" title="You Do It Then (Crypto%)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br />
                    <i>You Do It Then (If You're So Smart)</i> is a short, choose-your-own-adventure style experience made in Unreal Engine 5.2. It was originally made for the <a href="https://itch.io/jam/gmtk-2023">GMTK Game Jam 2023</a>, though
                    unfortunately issues with the game's file size (50 megabytes too many 😡) meant it could not be uploaded in time.<br /><br />
                    The game is primarily set inside of Game Engine, a sentient piece of software apparently capable of generating video games based on the end user's request. This attempts to meet the requirements of the theme, "roles reversed", insomuch as it
                    reverses the roles of the player and the game developer.<br /><br />
                    The game can be downloaded from <a href="https://harrisongowland.itch.io/you-do-it-then">my itch.io page</a>.<br /><br />
                    <b>What did I do?</b><br/>
                    I was convinced from the beginning that I wanted to make the game in UE 5.2, and that I wanted the game's conceit to primarily engage with reversing the roles of player and game developer in a way that would be amusing (or at least, that I would find
                    amusing to play later). To this end, the choose your own adventure format made the most sense: light on systems infrastructure, and able to be heavier on narrative and the sort of goofy antics I wanted the game to primarily be about.<br/><br/>
                    The game is made out of a series of cutscenes; the first of which depicts a character explaining to the first person protagonist the issue they're having making the game, before telling the player to do it themselves. The following cutscenes are a one-sided
                    conversation the player has with Game Engine, a character who attempts to build the player's requested game. I built these cutscenes in Sequencer: using Metahumans for the characters meant I could use my iPhone to produce reasonably good looking facial animation data
                    with little time cost, and I'm familiar with FMOD from using it in my Unity projects making it an obvious choice to serve as the backing engine for the audio.<br/>
                    <img style={{width: "100%", paddingTop: "20px", paddingBottom:"20px"}} src="https://i.gyazo.com/6e840476a1239364f908da54cdea1f7c.png" alt="A screenshot from You Do It Then featuring a the Game Engine character with three choices for what game to make - a visual novel, a platformer, and a game leveraging 'crypto currency'."></img>
                    All of the Game Engine cutscenes are launched from one of multiple data assets which enable them to interlink to each other. The data assets also contain integer time codes that tell the system when in the cutscene to skip to if the player presses the return key. At the end of
                    each of these cutscenes, the player gets one of three choices, or receives an ending (the game may also immediately close, if I decided in the moment that that would be funnier - see video at top of page).<br/>
                    <img style={{width: "100%", paddingTop: "20px", paddingBottom:"20px"}} src="https://i.gyazo.com/3f62eb40518c58b29c145ff14e604a0d.jpg" alt="A screenshot from You Do It Then featuring a computer with the Game Engine iconography."></img>
                    I also used Adobe After Effects to build the startup screen for Game Engine in the initial cutscene.<br/><br/>
                    <b>What would I do differently?</b><br/>
                    A lot of my regrets attached to this project are to do with not being able to upload it in time. My primary mistake in that respect was forgetting about <a href="https://itch.io/docs/butler/">butler</a>, itch.io's file upload API which can run in the Windows command-line. While attempting to upload directly to the website
                    has a file size limit of 1 gigabyte, butler doubles this limit to 2, meaning if I had remembered it in good time I would have been able to upload the project without compressing it (as it was, it refused to compress further than 1.05gb, annoyingly).<br/><br/>
                    The other mistake was probably attempting to make a project of this scope for a jam of this timeframe. Multiple separate cutscenes using Metahumans looks impressive, but the amount of time required to make each individual scene was a massive bottleneck; additionally, while using FMOD
                    made it much easier to handle audio events, attempting to drag FMOD events directly into the Sequencer timeline as the internet suggests should be possible caused the engine to crash, so I instead used an event trigger to start the relevant event, which created a further timesink as I nudged
                    triggers back and forth. As it is, some routes end abruptly.<br/>
                    <img style={{width: "100%", paddingTop: "20px", paddingBottom:"20px"}} src="https://i.gyazo.com/21d9911b494f7c87d4b58f17fe021545.jpg" alt="A screenshot from You Do It Then featuring a the Game Engine character with three choices for what game to make - a visual novel, a platformer, and a game leveraging 'crypto currency'."></img>
                    The smarter thing to do if I wanted to make a project of this kind would have been to scale down my ambitions for the graphical fidelity of the cutscenes, potentially even making them 2D to better match the off-the-wall tone of the story; this might have meant creating the project
                    in a different engine, such as Unity or even Twine, potentially (which would have end-run issues relating to file size).<br/><br/>
                    I would also, with time being no restraint, add subtitles (which proved to be impossible in the jam format, given the FMOD event bug meaning I could not reliably track what
                    subtitles should fire when).
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default YouDoItThen; 