import '../App.css';
import GamePageHeaderImage from "./pageutils";

function CardboardCode() {
    return (
        <div style={{ width: "100%" }}>
            <pre id="cardboardCode"><code className="hljs language-csharp">
                {`public int Calculate()
{
    List <float> angles = new List<float>();
    float currentAngle = 0; 
    for (int i = 0; i < 6; i++)
    {
        switch
         (i)
        {
            case 0:
                currentAngle = Vector3.Angle(transform.up, Vector3.up);
                break;
            case 1:
                currentAngle = Vector3.Angle(-transform.up, Vector3.up);
                break; 
            case 2:
                currentAngle = Vector3.Angle(transform.right, Vector3.up);
                break;
            case 3:
                currentAngle = Vector3.Angle(-transform.right, Vector3.up);
                break;
            case 4:
                currentAngle = Vector3.Angle(transform.forward, Vector3.up);
                break;
            case 5:
                currentAngle = Vector3.Angle(-transform.forward, Vector3.up);
                break;
        }
        angles.Add(currentAngle);
    }

    float minVal = angles.Min();
    int index = angles.IndexOf(minVal);

    //We now know which side is up. 

    switch (index)
    {
        case 0:
            index = 5;
            break;
        case 1:
            index = 2;
            break;
        case 2:
            index = 6;
            break;
        case 3:
            index = 1;
            break;
        case 4:
            index = 4;
            break;
        case 5:
            index = 3;
            break; 
    }

    return index; 
}`}</code></pre></div>
    )
}

function CardboardCourt(){
    return (
        <div>
            <GamePageHeaderImage image="https://pbs.twimg.com/media/FmH4sdrXgAARq_X?format=png&name=4096x4096" alt="The start screen for Cardboard Court, featuring logo and Halfpoint attribution" position="right 50% bottom 35%"></GamePageHeaderImage>
            <div className="GamePageBody">
                <img src="https://harrisongowland.co.uk/images/new%20king.png" style={{width: "50%", paddingTop: "20px", paddingBottom:"20px"}} alt="A screenshot from Cardboard Court, featuring a tutorial for the game's loop"/>
                <p>
                    <b>What is it?</b><br/>
                    <i>Cardboard Court</i> is a digital board game developed by myself and my friend <a href="https://www.linkedin.com/in/aherndan/">Dan Ahern</a> under our Halfpoint Games label for <a href="https://ldjam.com/events/ludum-dare/52/results">Ludum Dare 52</a>.
                     The game has the player take on the role of the royal Master of Harvests: the objective is to gather enough supplies, resources and influence to run the harvest festival successfully. Failure means execution.<br/><br/>
                     The gameplay loop involves rolling the dice, drawing an event card, responding to that event and either gaining or losing resources, and then playing those resources to generate score in three different categories. The player repeats this process until they
                     perform one complete circuit of the board, at which point if they have a high enough score, they win.
                     <img src="https://harrisongowland.co.uk/images/items%20found.png" style={{width: "100%", paddingTop: "20px", paddingBottom:"20px"}} alt="A screenshot from Cardboard Court, showing the screen for adding items to the Harvest Festival"></img>
                     <b>What did I do?</b><br/>
                     As with <a href="/games/closed-circuit">Closed Circuit</a> and given the short turnaround time for Ludum Dare, Dan and I shared most responsibilities relating to the game's production. I set up the opening cinematic with Cinemachine, the dice rolling mechanic (which
                     throws some dice then calculates each die's value by getting the angular difference between which way is up in the world and which way is up for the die after it's been rotated from being thrown - see below), and the event system.<br/><br/>
                     The game's Git repository can be <a href="https://github.com/harrisongowland/LudumDare52TakeTwo">viewed here</a>.<br/><br/>
                     <CardboardCode />
                     <hr />
                </p>
            </div>
        </div>
    )
}

export default CardboardCourt; 