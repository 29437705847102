import React, { useState } from "react";
import '../App.css';
import GamePageHeaderImage from './pageutils';
import drone1 from '../images/xnode-drone-1.png';
import drone2 from '../images/xnode-drone-2.png';
import drone3 from '../images/xnode-drone-3.png';
import drone4 from '../images/xnode-drone-4.png';
import drone5 from '../images/xnode-drone-5.png';
import ccsc from '../images/closed_circuit.jpg';

function TabContents(props) {
    return (
        <div style={{width: "100%"}}>
            <h3>{props.title}</h3>
            <img src={props.image} style={{ width: "100%", height: "auto" }} alt={props.alt}></img>
            <p style={{ width: "100%" }}>{props.description}</p>
        </div>
    )
}

function ClosedCircuit() {
    // eslint-disable-next-line
    const [currentTab, setCurrentTab] = useState(0);
    const displayTab = () => {
        switch (currentTab) {
            default:
                return null;
            case 1:
                return <TabContents title="Patrolling" image={drone1} alt="XNode FSM for the patrol behaviour of the drone in Closed Circuit" description="The drone starts at the left-most node, which causes it to start at the patrol point marked at 0 in its index. It then waits until it has reached that point, waits 0.1 seconds, then moves to the next point in the list. (If the drone has exhausted all points, moving to the 'next' point moves it back to point 0." />
            case 2:
                return <TabContents title="Detecting" image={drone2} alt="XNode FSM for the detection behaviour of the drone in Closed Circuit" description="The drone enters the detection phase when the player enters its line of sight. The drone will turn to constantly be facing the player, it will wait 0.7 seconds, and then it will move to the attacking phase." />
            case 3:
                return <TabContents title="Attacking" image={drone3} alt="XNode FSM for the attack behaviour of the drone in Closed Circuit" description="The drone enters the attacking phase when the Change State action is called in the detection phase. It will open fire (which is functionality we have yet to implement), wait one second, and then start pursuing the player. It will continue pursuing until the player dies or the player leaves the drone's line of sight." />
            case 4:
                return <TabContents title="On alert" image={drone4} alt="XNode FSM for the alert behaviour of the drone in Closed Circuit" description="The drone enters the alert phase if the player has left the drone's line of sight and it was previously in either the attacking or detected phase. It will wait 0.8 seconds and then 'scan' - rotate around until either it has rotated a certain amount or has detected the player, at which point it will automatically enter the detection phase again.
            If it doesn't detect the player, it will move to its next patrol point and scan there. It will repeat this process up to four times before returning to its patrol state." />
            case 5:
                return <TabContents title="Stunned" image={drone5} alt="XNode FSM for the stunned behaviour of the drone in Closed Circuit" description="The drone enters the stunned phase when the players trikes its weak point on its back. This doesn't kill the drone - the drone can only be destroyed by solving an environmental puzzle. It does this for fifteen seconds before unfreezing, resetting its animation to normal, and returning to its patrol state." />
        }
    }
    return (
        <div>
            <GamePageHeaderImage image={ccsc} position="center" alt="A screenshot from Closed Circuit depicting a small robot being pursued by a large robot"></GamePageHeaderImage>
            <div className='GamePageBody'>
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/cJTzzWYz-tg" title="Closed Circuit prototype playthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p style={{ textAlign: "center" }}>The subtitles in the gameplay video for <i>Closed Circuit</i> include language some viewers may find offensive.</p>
                <p>
                    <b>What is it?</b><br />
                    <i>Closed Circuit</i> is an in-progress first- and third-person roguelike adventure game beign developed by me and my friend <a href="https://www.linkedin.com/in/aherndan/">Dan Ahern</a> in Unity under our Halfpoint Games label.
                    It follows an unnamed security guard at a science facility which becomes overrun with robots; the guard is trapped in his security booth and must pilot a janitorial drone through the facility to rescue other scientists and escape,
                    as well as discover what caused the outbreak.<br /><br />
                    The gameplay loop Dan and I envisioned for this game alternated between the third-pesron janitorial drone being piloted through the facility, upgraded with new tools and weapons, and rescuing scientists trapped in the robot outbreak.
                    Meanwhile, the first-person player can solve puzzles from inside the security booth.<br /><br />
                    <b>What did I do?</b><br />
                    Dan and I shared most responsibilities relating to the game's production as we're both C# programmers; one of my key contributions was the development of the AI used by the game's enemies. It uses a custom node-based finite state machine
                    system I developed in the node system framework XNode to track the enemy's state - whether to follow its default patrol route, whether to chase the player, or whether to be on high alert - Unity's inbuilt NavMesh to pursue the player when
                    alerted, and Sensor Toolkit to handle player detection. An explanation of the basic enemy's AI is detailed in the tabs below.<br />
                    <div className="tab">
                        <button className="tabitems" onClick={() => setCurrentTab(1)}>Patrol</button>
                        <button className="tabitems" onClick={() => setCurrentTab(2)}>Detect</button>
                        <button className="tabitems" onClick={() => setCurrentTab(3)}>Attack</button>
                        <button className="tabitems" onClick={() => setCurrentTab(4)}>Alert</button>
                        <button className="tabitems" onClick={() => setCurrentTab(5)}>Stunned</button>
                        <button className="tabitems" onClick={() => setCurrentTab(0)}>Close tab</button>
                    </div>
                    {displayTab()}
                    <hr />
                    I also built the various player controllers in the game, for the player, the drone, and the cinematic controller (see the video above; the player still has control of the first person camera during cutscenes, but their rotation is limited and
                    they cannot move around.)<br /><br />
                    We used Jira for our issue tracking and quality assurance, and Confluence for our systems documentation.<br /><br />
                    The project is currently on hold indefinitely, as Tin Hearts was close to release during a critical stage in its development.
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default ClosedCircuit;