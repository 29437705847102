import '../App.css';
import GamePageHeaderImage from "./pageutils";

function SolihullRemaster() {
    return (
        <div>
            <GamePageHeaderImage image="https://img.itch.zone/aW1nLzEyOTkwODczLmpwZw==/original/feYU6p.jpg" position="center" objectFit="contain" repeat="repeat-x" alt="Key art for P6 Solihull: The Orange" />
            <div className="GamePageBody">
                <p>
                    <b>What is it?</b><br />
                    <i>P6 Solihull: The Orange</i> is an RPG Maker VXA re/demaster of <a href="https://harrisongowland.co.uk/games/p6-solihull">P6: Solihull</a>, a short 3D RPG game
                     I made in Unity in 2021. Like its predecessor, it follows the story of Leonard who travels to the now medieval city of Solihull, attempting to avert an
                     impending conflict caused by shortages of affordable off-brand fizzy drinks by seizing Solihull's supply of "Almost Orange", the city's famous and
                     less than primo beverage. These plans are turned upside down by an altercation with <b style={{color:"red"}}>Greg</b>, Guardian and Lord President of
                     Solihull. The player must defeat Greg over three combat phases, utilising a similar (though more robust, thanks to RPG Maker's out of the box combat
                     functionality) system to the original. <br />
                     <img style={{marginTop: "10px", marginBottom: "10px" }} src="https://img.itch.zone/aW1hZ2UvMjE5NzY3Mi8xMjk5MDc2NC5qcGc=/original/pjhjWT.jpg" alt="A shot of Greg, Guardian of Solihull. The subtitle reads 'Greg, Guardian of Solihull emerged!'"></img><br />
                    <b>What did I do?</b><br />
                    I made the game over the course of a couple of days for <a href="https://itch.io/jam/opengamearts-summer-game-jam-2023">the 2023 instalment of the OpenGameArt jam</a>,
                    and so uses a number of sound effects and musical tracks available under liberal IP licenses, mostly CC0. The full credits list can be viewed on
                    <a href="https://harrisongowland.itch.io/p6-solihull-the-orange">the game's itch page</a>. <i>The Orange</i> lacks my voice acting from the original (probably for the 
                    best), and uses in-engine art assets due to the short turnaround time. This limited the scope of the narrative, given RPG Maker's base tilesets are geared towards games
                    in more medieval settings, necessitating a rethink about the story of Leonard's visit to Solihull. With more time, I would have found a different tileset to give more
                    flexibility about creative direction over the story.<br />
                    <img style={{marginTop: "10px", marginBottom: "10px" }} src="https://img.itch.zone/aW1hZ2UvMjE5NzY3Mi8xMjk5MDc2Mi5qcGc=/original/sBv0RQ.jpg" alt="The game over screen from P6 Solihull: The Orange. It depicts a city on fire, and the caption reads 'The world when no Almost Orange (game over)'."></img>
                    I built the three combat phases using a combination of the engine's built in combat system, which handles the enemy picking its moves internally, and custom events, 
                    which take place every time the player depletes Greg's HP to a certain threshold, playing dialogue and forcing certain moves to heal the enemy (which was not a function
                    supported by the previous game.) As part of a project, I am planning to remaster P6: Solihull in other engines also and will update this page to contain those
                    instalments as and when they become available. 
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default SolihullRemaster; 