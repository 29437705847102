import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import TinHearts from './pages/tinhearts';
import RalphAdventure from './pages/ralphadventure';
import ClosedCircuit from './pages/closedcircuit';
import Solihull from './pages/p6solihull';
import Gallery from './pages/lastminutegallery';
import SecretProject from './pages/secretproject';
import YouDoItThen from './pages/you-do-it-then';
import Parallax from './pages/parallax';
import CraneOdyssey from './pages/craneodyssey';
import CardboardCourt from './pages/cardboardcourt';
import PresidentCabbage from './pages/presidentcabbage';
import KetchupMaze from './pages/ketchupmaze';
import InterestsOfJustice from './pages/interestsofjustice';
import SolihullRemaster from './pages/p6solihullremaster';
import ImagineChatHappy from './pages/onemustimaginechathappy';

import ParallaxSC from './images/Parallax2.jpg';
import ClosedCircuitSC from './images/closed_circuit.jpg';
import Confused from './images/confused.jpg';
import PresidentCabbageImage from './images/president_cabbage.jpg';
import KetchupMazeImage from './images/ketchupmaze.png';
import JusticeImage from './images/justice-hero.jpg';
import SolihullOrangeImage from './images/solihull-the-orange.jpg';

function App(props) {
  return (
    <Router>
      <div className="App">
        <div className="HeroArea">
          <h1>
            Harrison Gowland
          </h1>
          <h2>
            {props.subtitle}
          </h2>
        </div>
        <ul>
          <MainListItem location="home" />
          <MainListItem location="games" />
          <MainListItem location="writing" />
          <MainListItem location="more" />
        </ul>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route exact path="/games" element={<Games />} />
          <Route exact path="/writing" element={<Writing />} />
          <Route exact path="/writing/fiction-writing" element={<FictionWriting />} />
          <Route exact path="/writing/non-fiction" element={<NonFictionWriting />} />
          <Route exact path="/more" element={<More />} />
          <Route path="/games/tin-hearts" element={<TinHearts />} />
          <Route path="/games/ralph-adventure" element={<RalphAdventure />} />
          <Route path="/games/closed-circuit" element={<ClosedCircuit />} />
          <Route path="/games/p6-solihull" element={<Solihull />} />
          <Route path="/games/secret-project" element={<SecretProject />} />
          <Route path="/games/last-minute-gallery" element={<Gallery />} />
          <Route path="/games/you-do-it-then" element={<YouDoItThen />} />
          <Route path="/games/parallax" element={<Parallax />} />
          <Route path="/games/cranes-odyssey" element={<CraneOdyssey />} />
          <Route path="/games/cardboard-court" element={<CardboardCourt />} />
          <Route path="/games/ketchup-maze" element={<KetchupMaze />} />
          <Route path="/writing/president-cabbage" element={<PresidentCabbage />} />
          <Route path="/writing/eric-brungus-tonight" element={<More />} />
          <Route path="/writing/last-goldfish" element={<More />} />
          <Route path="/games/interests-of-justice" element={<InterestsOfJustice />} />
          <Route path="/games/p6-solihull-remasters" element={<SolihullRemaster />} />
          <Route path="/games/one-must-imagine-chat-happy" element={<ImagineChatHappy />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

function Footer(props) {
  return (
    <div className='footer'>
      <p>Website &copy; Harrison Gowland, 2023.{props.additionalCopyright}</p>
    </div>
  )
}

function MainListItem(props) {
  const navigate = useNavigate();
  return (
    <li onClick={() => {
      if (props.location === "home") {
        navigate("/");
        window.scrollTo(0, 0);
      }
      else {
        navigate("/" + props.location);
        window.scrollTo(0, 0);
      }
    }}>
      <span className="capitaliseFirstLetter">{props.location}</span>
    </li>
  )
}

function HalfwidthBox(props) {
  const navigate = useNavigate();
  return (
    <div className={"box " + props.boxClass} onClick={() => {
      if (props.external !== "true" || props.external == null){
        navigate("/" + props.location);
        window.scrollTo(0, 0);
      }
      else {
        window.location.href = props.location;
        return null; 
      }
    }}>
      <div className="boxImage" style={{ backgroundImage: "url(" + props.imageSource + ")" }}>
        <EngineDisplay display={props.engineDisplay} engine={props.engine} color="white"></EngineDisplay>
      </div>
      <div className="boxContent">
        <h2>{props.title}</h2>
        <p>{props.showRole && <div><i><span>{props.role}</span></i><br /></div>}{props.description}</p>
      </div>
    </div>
  )
}

function EngineDisplay(props) {
  return (
    <div className="engineDisplay" style={{ backgroundColor: props.color, display: props.display }}>
      {props.engine}
    </div>
  )
}

function Home() {
  return (
    <div>
      <div className="PageBody">
        <p>
          <b>Harrison Gowland</b> (me) is a game developer and writer from the UK. I'm currently a VR game designer for FuturLab on PowerWash Simulator, and previously was a level designer for indie studio Rogue Sun's puzzle adventure game
          <i> <a href="https://harrisongowland.co.uk/tin-hearts.html">Tin Hearts</a></i>, out on all platforms.<br /><br />
          Thank you for visiting my webpage. You can contact me on <a href="https://www.linkedin.com/in/harrison-gowland-49845999/">Linkedin</a>, by <a href="mailto:hjgowland@gmail.com">email</a> and nowhere else <span className="rotatingText">go<span style={{ opacity: 0.6 }}>od</span><span style={{ opacity: 0.4 }}>by</span><span style={{ opacity: 0.2 }}>ee</span><span style={{ opacity: 0.05 }}>ee</span></span>
        </p>
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engineDisplay="none" location="games" title="Games" description="All the video game projects I've worked on, made or been involved with" imageSource="https://cdn.cloudflare.steamstatic.com/steam/apps/1831700/capsule_616x353.jpg?t=1680801072" imageAlt="Key art for Tin Hearts, featuring the protagonist Albert Butterworth" />
        <HalfwidthBox boxClass="normalbox" engineDisplay="none" location="writing" title="Writing" description="All of my writing work: fiction, journalism, games narrative" imageSource={PresidentCabbageImage} />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engineDisplay="none" location="more" title="Other" description="Everything else I've done that doesn't fit into either games or writing" imageSource={Confused} imageAlt="A picture of a man at a laptop looking confused" />
      </div>
    </div>
  )
}

function Games() {
  //eslint-disable-next-line
  const [currentCategory, setCategory] = useState(0);
  const displayCategory = () => {
    switch (currentCategory) {
      default:
        return <AllGames />;
      case 0:
        return <AllGames />;
      case 1:
        return <UnityGames />;
      case 2:
        return <UnrealGames />;
      case 3:
        return <OtherGames />;
    }
  }

  return (
    <div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="SelectorBox" style={{ width: "80%", padding: "10px" }}>
          <input type="radio" id="allgames" name="games_shown" value="allgames" defaultChecked onChange={() => setCategory(0)} />
          <label for="allgames">Show all games</label>
          <input type="radio" id="unitygames" name="games_shown" value="unitygames" onChange={() => setCategory(1)} />
          <label for="unitygames">Show only Unity games</label>
          <input type="radio" id="unrealgames" name="games_shown" value="unrealgames" onChange={() => setCategory(2)} />
          <label for="unrealgames">Show only Unreal games</label>
          <input type="radio" id="othergames" name="games_shown" value="othergames" onChange={() => setCategory(3)} />
          <label for="othergames">Show only other engine games</label>
        </div>
      </div>
      {displayCategory()}
    </div>
  )
}

function AllGames() {
  return (
    <div>
      <div className="PageBody">
        <div className="boxes">
          <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/tin-hearts" title="Tin Hearts" role="Role: Level and game designer" description="Tin Hearts is a first person puzzle adventure game by Rogue Sun, out now on all platforms." imageSource="https://cdn.akamai.steamstatic.com/steam/apps/1831700/header.jpg?t=1687348274" imageAlt="Key art for Tin Hearts, featuring the protagonist Albert Butterworth" />
          <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/one-must-imagine-chat-happy" title="One Must Imagine Chat Happy" role="Role: Game and systems designer" imageSource="https://i3.ytimg.com/vi/x1N2rTIRsus/maxresdefault.jpg" imageAlt="A man in a suit rolling a boulder up a hill. The boulder has the Twitch logo superimposed on it."></HalfwidthBox>
        </div>
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/secret-project" title="Secret project" role="Role: Interim tech designer" imageSource="https://images.pexels.com/photos/874242/pexels-photo-874242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" imageAlt="A picture of a confused looking man using a laptop" />
        <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/you-do-it-then" title="You Do It Then (If You're So Smart)" role="Role: Systems and level designer, voice actor (bad), sole developer" imageSource="https://i.gyazo.com/6a43714b41f8b77f3f94cf8e3dba10a3.png" imageAlt="Screenshot from You Do It Then featuring one of the characters gesturing at a desk" />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/ralph-adventure" title="Ralph's Ridiculous Adventure" role="Role: Programmer and systems designer" imageSource="https://img.itch.zone/aW1nLzc1OTU4NTQucG5n/original/4Rr8u%2F.png" imageAlt="Key art for Ralph's Ridiculous Adventure, featuring the protagonist riding the snake Mr. Slithers" />
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/last-minute-gallery" title="The Last Minute Gallery" role="Role: Programmer, musical artist (bad), sole developer" imageSource="https://static.jam.host/content/33f/f2/z/4faab.jpg.480x384.fit.jpg" imageAlt="Key art for The Last Minute Gallery" />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/p6-solihull" title="P6: Solihull" role="Role: Programmer, voice actor (bad), sole developer" imageSource="https://img.itch.zone/aW1nLzU4NjM4MDkucG5n/original/DwOtV6.png" imageAlt="Key art for P6: Solihull" />
        <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/interests-of-justice" title="The Interests of Justice" role="Role: Blueprint designer, voice actor (bad), sole developer" imageSource={JusticeImage} imageAlt="Key art for The Interests of Justice" />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="GameMaker Studio 2" engineDisplay="flex" showRole="true" location="games/parallax" title="Parallax" role="Role: Pixel artist, writer and systems designer" imageSource={ParallaxSC} imageAlt="A gameplay screenshot from Parallax" />
        <HalfwidthBox boxClass="normalbox" engine="Spinoff" engineDisplay="flex" showRole="true" location="games/cranes-odyssey" title="Crane's Odyssey" role="Role: Writer, character design, sole developer" imageSource="https://img.itch.zone/aW1nLzEwMTY4MjYzLnBuZw==/315x250%23c/kmtIxM.png" imageAlt="Key art for Crane's Odyssey featuring the two main characters and the title" />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Paint" engineDisplay="flex" showRole="true" location="games/ketchup-maze" title="Ketchup Maze" role="Role: 2D artist, sole developer" imageSource={KetchupMazeImage} imageAlt="A badly drawn maze in 2D, with the word Start at the start and a ketchup bottle at the end" />
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/cardboard-court" title="Cardboard Court" role="Role: Programmer, systems designer" imageSource="https://pbs.twimg.com/media/FmH4sdrXgAARq_X?format=png&name=4096x4096" imageAlt="Key art for Cardboard Court featuring the logo and Halfpoint Games attribution" />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="RPG Maker" engineDisplay="flex" showRole="true" location="games/p6-solihull-remasters" title="P6 Solihull: The Orange" role="Role: Game designer, sole developer" imageSource={SolihullOrangeImage} imageAlt="Key art for P6 Solihull: The Orange" />
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/closed-circuit" title="Closed Circuit" role="Role: Programmer, systems/narrative designer" imageSource={ClosedCircuitSC} imageAlt="Key art for P6: Solihull, featuring the protagonist riding a bus." />
      </div>
    </div>
  )
}

// eslint-disable-next-line
function UnityGames() {
  return (
    <div>
      <div className="PageBody">
        <div className="boxes">
          <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/tin-hearts" title="Tin Hearts" role="Role: Level and game designer" description="Tin Hearts is a first person puzzle adventure game by Rogue Sun, out now on all platforms." imageSource="https://cdn.akamai.steamstatic.com/steam/apps/1831700/header.jpg?t=1687348274" imageAlt="Key art for Tin Hearts, featuring the protagonist Albert Butterworth" />
          <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/ralph-adventure" title="Ralph's Ridiculous Adventure" role="Role: Programmer and systems designer" imageSource="https://img.itch.zone/aW1nLzc1OTU4NTQucG5n/original/4Rr8u%2F.png" imageAlt="Key art for Ralph's Ridiculous Adventure, featuring the protagonist riding the snake Mr. Slithers" />
        </div>
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/closed-circuit" title="Closed Circuit" role="Role: Programmer, systems/narrative designer" imageSource={ClosedCircuitSC} imageAlt="Key art for P6: Solihull, featuring the protagonist riding a bus." />
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/last-minute-gallery" title="The Last Minute Gallery" role="Role: Programmer, musical artist (bad), sole developer" imageSource="https://static.jam.host/content/33f/f2/z/4faab.jpg.480x384.fit.jpg" imageAlt="Key art for The Last Minute Gallery" />
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/p6-solihull" title="P6: Solihull" role="Role: Programmer, voice actor (bad), sole developer" imageSource="https://img.itch.zone/aW1nLzU4NjM4MDkucG5n/original/DwOtV6.png" imageAlt="Key art for P6: Solihull" />
        <HalfwidthBox boxClass="normalbox" engine="Unity" engineDisplay="flex" showRole="true" location="games/cardboard-court" title="Cardboard Court" role="Role: Programmer, systems designer" imageSource="https://pbs.twimg.com/media/FmH4sdrXgAARq_X?format=png&name=4096x4096" imageAlt="Key art for Cardboard Court featuring the logo and Halfpoint Games attribution" />
      </div>
    </div>
  )
}

function UnrealGames() {
  return (
    <div>
      <div className="PageBody">
        <div className="boxes">
          <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/secret-project" title="Secret project" role="Role: Interim tech designer" imageSource="https://images.pexels.com/photos/874242/pexels-photo-874242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" imageAlt="A picture of a confused looking man using a laptop" />
          <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/one-must-imagine-chat-happy" title="One Must Imagine Chat Happy" role="Role: Game and systems designer" imageSource="https://i3.ytimg.com/vi/x1N2rTIRsus/maxresdefault.jpg" imageAlt="A man in a suit rolling a boulder up a hill. The boulder has the Twitch logo superimposed on it."></HalfwidthBox>
        </div>
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/interests-of-justice" title="The Interests of Justice" role="Role: Blueprint designer, voice actor (bad), sole developer" imageSource={JusticeImage} imageAlt="Key art for The Interests of Justice" />
        <HalfwidthBox boxClass="normalbox" engine="Unreal" engineDisplay="flex" showRole="true" location="games/you-do-it-then" title="You Do It Then (If You're So Smart)" role="Role: Systems and level designer, voice actor (bad), sole developer" imageSource="https://i.gyazo.com/6a43714b41f8b77f3f94cf8e3dba10a3.png" imageAlt="Screenshot from You Do It Then featuring one of the characters gesturing at a desk" />
       </div>
    </div>
  )
}

function OtherGames() {
  return (
    <div>
      <div className="PageBody">
        <div className="boxes">
          <HalfwidthBox boxClass="normalbox" engine="GameMaker Studio 2" engineDisplay="flex" showRole="true" location="games/parallax" title="Parallax" role="Role: Pixel artist, writer and systems designer" imageSource={ParallaxSC} imageAlt="A gameplay screenshot from Parallax" />
          <HalfwidthBox boxClass="normalbox" engine="Spinoff" engineDisplay="flex" showRole="true" location="games/cranes-odyssey" title="Crane's Odyssey" role="Role: Writer, character design, sole developer" imageSource="https://img.itch.zone/aW1nLzEwMTY4MjYzLnBuZw==/315x250%23c/kmtIxM.png" imageAlt="Key art for Crane's Odyssey featuring the two main characters and the title" />
        </div>
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Paint" engineDisplay="flex" showRole="true" location="games/ketchup-maze" title="Ketchup Maze" role="Role: 2D artist, sole developer" imageSource={KetchupMazeImage} imageAlt="A badly drawn maze in 2D, with the word Start at the start and a ketchup bottle at the end" />
        <HalfwidthBox boxClass="normalbox" engine="RPG Maker" engineDisplay="flex" showRole="true" location="games/p6-solihull-remasters" title="P6 Solihull: The Orange" role="Role: Game designer, sole developer" imageSource={SolihullOrangeImage} imageAlt="Key art for P6 Solihull: The Orange" />
      </div>
    </div>
  )
}

// eslint-disable-next-line
function Writing() {
  return (
    <div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engineDisplay="none" location="writing/fiction-writing" title="Fiction" description="Games narrative and fiction stories" imageSource={PresidentCabbageImage} imageAlt="A shot of Harrison Gowland talking about President Cabbage on KMTV" />
        <HalfwidthBox boxClass="normalbox" engineDisplay="none" location="writing/non-fiction" title="Non-fiction" description="Non-fiction essays and articles" imageSource="https://web.archive.org/web/20201120132421im_/https://usercontent.one/wp/www.parallaxmedia.one/wp-content/uploads/2020/11/1510202022273370940.jpg" />
      </div>
    </div>
  )
}

function FictionWriting(){
  return (
    <div>
      <div className="PageBody">
        <div className="boxes">
          <HalfwidthBox boxClass="normalbox" engine="Fiction" engineDisplay="flex" showRole="true" location="writing/president-cabbage" title="President Cabbage" role="A novella about a cabbage that becomes President of the United States" imageSource={PresidentCabbageImage} imageAlt="A picture of Harrison Gowland giving an interview about President Cabbage" />
          <HalfwidthBox boxClass="normalbox" engine="Games writing" engineDisplay="flex" showRole="true" location="games/cranes-odyssey" title="Crane's Odyssey" role="A visual novel about a man trying to find his husband in a parallel dimension" imageSource="https://img.itch.zone/aW1nLzEwMTY4MjYzLnBuZw==/315x250%23c/kmtIxM.png" imageAlt="Key art for Crane's Odyssey featuring the two main characters and the title" />
        </div>
      </div>
      <div className="boxes">
        <HalfwidthBox boxClass="normalbox" engine="Fiction" engineDisplay="flex" showRole="true" location="writing/last-goldfish" title="The Last Goldfish in Canary Wharf" role="A story about steampunk thieves in an underwater capital" imageSource="https://images.pexels.com/photos/88514/city-building-night-view-night-88514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" imageAlt="A stock photograph of Canary Wharf" />
        <HalfwidthBox boxClass="normalbox" engine="Games writing" engineDisplay="flex" showRole="true" location="writing/eric-brungus-tonight" title="Eric Brungus Tonight" role="A Twine game about a doomed talk show host" imageSource="https://images.pexels.com/photos/713149/pexels-photo-713149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" imageAlt="A stock photograph of a theatre stage" />
      </div>
    </div>
  )
}

function NonFictionWriting() {
  return (
    <div>
      <div className="PageBody" style={{ flexDirection : "column", justifyContent: "flex-start", alignItems: "center" }}>
       <div className="boxes">
          <HalfwidthBox external="true" location="https://www.huffingtonpost.co.uk/entry/jackie-weaver-meme-viral_uk_602d2db6c5b67c32961a85a4" boxClass="normalbox" engineDisplay="flex" engine="Huffington Post" title="Jackie Weaver and memes" description="An article discussing the serious cultural impact of treating figures like Jackie Weaver as a meme" imageSource="https://www.thesun.co.uk/wp-content/uploads/2021/02/NINTCHDBPICT000634399558.jpg" imageAlt="A picture of Jackie Weaver on a Zoom call" />
          <HalfwidthBox external="true" location="https://whynowgaming.com/why-you-should-make-games-nobody-can-play/" boxClass="normalbox" engineDisplay="flex" engine="whynow Gaming" title="Why you should make games nobody can play" description="An article about the benefits of copyright theft viz improving as a game developer" imageSource="https://spaces.whynowgaming.com/uploads/2023/02/gavel-2048x1365.webp" imageAlt="A picture of a gavel next to a pile of folders" />
       </div>
       <div className="boxes">
        <HalfwidthBox external="true" location="https://gaymingmag.com/2021/04/persona-5-undermines-own-themes-queer-communities/" boxClass="normalbox" engineDisplay="flex" engine="Gayming Magazine" title="Persona 5's queer portrayals undermine its themes" description="An article about how Persona 5's portrayal of queer people undermines its themes of rebellion and cultural revolution" imageSource="https://gaymingmag.com/wp-content/uploads/2021/04/xryujipersona5-390x205.jpg.pagespeed.ic.BKNQskiTSY.webp" imageAlt="A promotional image of Ryuji Sakamoto from Persona 5"></HalfwidthBox>
        <HalfwidthBox external="true" location="https://bbench.co.uk/2020/12/08/joe-bidens-victory-is-a-lifeline-for-the-games-industry/" boxClass="normalbox" engineDisplay="flex" engine="Backbench UK" title="Joe Biden's victory a lifeline for games" description="An article about why Joe Biden's victory is good for the video games industry, particularly with regards to Section 230 of the Communications Decency Act" imageSource="https://cdn2.picryl.com/photo/2023/02/07/president-joe-biden-delivers-the-2023-state-of-the-union-address-cropped-893f1a-1024.jpg" imageAlt="A picture of President Joe Biden smiling while delivering the State of the Union address"></HalfwidthBox>
       </div>
       <div className='boxes'>
        <HalfwidthBox external="true" location="https://web.archive.org/web/20221207201648/https://www.parallaxmedia.one/2020/11/05/the-signifier-is-a-clever-dream-based-puzzler-held-back-by-technical-flaws/" boxClass="normalbox" engineDisplay="flex" engine="Parallax Media" title="The Signifier is a clever puzzler held back by technical flaws" description="An opinion piece about video game The Signifier, its merits as a puzzle game and its technical flaws. The site now seems gone - link goes to Wayback Machine." imageSource="https://web.archive.org/web/20201120132421im_/https://usercontent.one/wp/www.parallaxmedia.one/wp-content/uploads/2020/11/1510202022273370940.jpg" imageAlt="Promotional art from The Signifier"></HalfwidthBox>
        <HalfwidthBox external="true" location="https://web.archive.org/web/20210429144611/https://play.jumpcutonline.co.uk/2021/04/29/second-chance-trackmania-nations-forever/" boxClass="normalbox" engineDisplay="flex" engine="JumpCut Play" title="Second Chance: TrackMania Nations Forever" description="An article about the 2008 racing game Trackmania Nations Forever and how it still stands up today, especially in the context of the pandemic" imageSource="https://web.archive.org/web/20231016235121/https://lh3.googleusercontent.com/ra0IonyWEQyAsW1zaP9Uu6T7lBpeVTORHl3_ZvCkNuCneXDmOGfP73WIJlxPJHVvZ5p-mtrIk7bgKBx_5rDMSnCuAZTV0NLtV0U872enygtHrMJJbQG8nN2v6D_Z5FXL_nQmo-A" imageAlt="A gameplay screenshot from Trackmania Nations Forever"></HalfwidthBox>
       </div>
      </div>
    </div>
  )
}

function More() {
  return (
    <div>
      <div className="PageBody" style={{ flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <p>Still working on this page. I'll get to it eventually dw. While we're waiting here's wonderwall</p>
        <iframe style={{ padding: "20px" }} width="948" height="711" src="https://www.youtube.com/embed/bx1Bh8ZvH84" title="Oasis - Wonderwall (Official Video)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  )
}

function Error() {
  return (
    <div>
      <div className="PageBody" style={{ flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <p>You've found a page that doesn't exist. Good for you! Here's wonderwall</p>
        <iframe style={{ padding: "20px" }} width="948" height="711" src="https://www.youtube.com/embed/bx1Bh8ZvH84" title="Oasis - Wonderwall (Official Video)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default App;
