import '../App.css';
import GamePageHeaderImage from './pageutils';

function ImagineChatHappy() {
    return (
        <div>
            <GamePageHeaderImage image="https://i3.ytimg.com/vi/x1N2rTIRsus/maxresdefault.jpg" objectFit="contain" alt="A man in a business suit rolling a boulder up a hill. The boulder has the Twitch logo superimposed on it." />
            <div className="GamePageBody">
                <div class="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/x1N2rTIRsus?list=PLhyhuizYSJlOY5Yat0caF45Yvh2Z4jeWF" title="I Made The Sisyphus Meme Into a Twitch Chat Game" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br />
                    <i>One Must Imagine Chat Happy</i> is a Twitch-enabled Unreal Engine 5 game in which the player must roll a ball up a hill by pressing the left and right
                    arrow keys to balance the ball on a hill. Meanwhile, viewers in Twitch chat can vote for different things to happen, which can hinder the player in ways
                    ranging from mildly inconvenient to losing on the spot.<br /><br />
                    The game takes advantage of the Unreal plugin <a href="https://www.unrealengine.com/marketplace/en-US/product/twitchworks">TwiWorks</a>, which simplifies the process of connecting to the Twitch API from an Unreal game, and exposes a
                    number of Twitch functions to Unreal blueprints, primarily accessing the content of chat messages and determining whether the messager is a subscriber or not.<br />
                    I built the game over the course of a week and a half for a short YouTube series on my channel - the full series, in which I discuss building the game and
                    iterating on its design, <a href="https://www.youtube.com/playlist?list=PLhyhuizYSJlOY5Yat0caF45Yvh2Z4jeWF">can be viewed here</a>.<br /><br />
                    <b>What did I do?</b><br />
                    I wanted to design a game around the concept of the Sisyphean myth, mostly because the Sisyphus meme was relatively popular on YouTube at that point in time
                    and so making a video about it would hopefully generate engagement (this was borne out - it has been my most successful series for a while). At first, I
                    wanted chat itself to be the Sisyphus character rolling the ball up the hill, making it a sort of idle game in the vein of other livestreaming phenomena
                    such as Twitch Plays Pokemon - however, issues with latency and difficulty finding a control sensitivity that felt satisfying made this prohibitively
                    difficult.<br /><br />
                    Instead, I opted to make the game about the on-site player using the left and right arrow keys to balance the boulder, and instead of influencing the
                    ball's position directly, chat instead affects the player's ability to balance by voting for different things: gusts of wind to push the ball one way
                    or the other, inverting the player's controls, or, if a truly significant number of votes are gathered, killing the player on the spot.<br/><br />
                    I built the blueprint infrastructure that enabled both the on-site boulder balancing controls and the Twitch integration, while the Twitch integration
                    itself is done by the TwiWorks plugin. Partially thanks to the plugin's ease of use, and <a href="https://www.youtube.com/watch?v=_S3EwxNCLBk">this video by 
                    Rikku VR</a> which was highly instructive, getting Twitch working was relatively unchallenging.<br /><br />
                    For the future, I would like to change the landing page that the user ends up on after connecting their Twitch channel - I'm not sure whether this can be done
                    within the plugin or whether I would need to write my own implementation to permit that.<br />
                </p>
            </div>
        </div>
    )
}

export default ImagineChatHappy; 