import '../App.css';
import GamePageHeaderImage from './pageutils';
import JusticeImage from '../images/justice-hero.jpg'; 
import JusticeMaterialBlueprint from '../images/justice-material-blueprint.png';

function InterestsOfJustice(){
    return (
        <div>
            <GamePageHeaderImage image={JusticeImage} objectFit="contain" alt="The key art for The Interests of Justice"/>
            <div className="GamePageBody">
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/Bhrz3iTjrBA" title="O2A2 2023 VN Game Jam Entries! [Round 2!]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br/>
                    <i>The Interests of Justice</i> is a short visual novel made in Unreal Engine 5.2 for the <a href="https://itch.io/jam/o2a2-2023">O2A2 Jam</a> (O2A2 for <b>O</b>nly <b>O</b>ne of <b>A</b>ny <b>A</b>sset) - the stipulations of that jam being
                    that the visual novel may only have one character, one background, one music track, one thousand words, and so on in that fashion.<br/>
                    The game takes place in South Sminkerton County Court, where the silent protagonist attempts to get an automated British judge to supply public services, to limited success. 
                    The player clicks through dialogue and then makes a choice on a keypad running from 0-9 to interact with the automated system.<br/>
                    The game has various endings depending on what public service is requested, including marriage licenses, financial liens, name changes and criminal case settlement.
                    <br />The game can be downloaded from <a href="https://harrisongowland.itch.io/the-interests-of-justice">my itch.io page</a>.
                    <br /><br />
                    <b>What did I do?</b><br/>
                    I developed the game in 2023 in UE 5.2 as an entry for the O2A2 Jam. I was previously working on a Unreal Engine game set in a courtroom, so retooling my existing
                    project for the purposes of the jam; at first I contemplated it being a serious story about an actual legal case, but decided in the face of the strict limitation on
                    word count that a non-linear experience that didn't take itself seriously would probably be more achievable in the time available, so instead the story became about
                    a courthouse delivering public legal services in the same manner as one of those automated phone menus. This meant the dialogue itself could be broken up episodically
                    into very short blocks. 
                    <br />
                    <div style={{display: "flex", alignItems:"center", justifyContent:"center"}}>
                        <img src="https://img.itch.zone/aW1hZ2UvMjE5MzYxNS8xMjk2MjMxNi5qcGc=/original/JOeBXM.jpg" alt="A screenshot from The Interests of Justice. The judge is saying 'Press any key to speak to a therapist about how you'll live with yourself for doing this.'" style={{width: "100%", alignItems:"center", paddingTop: "20px", paddingBottom:"20px"}} />
                    </div>
                    The character and background are both static images; the former rendered out from Reallusion's Character Creator 4 as four .png images to cover all of the different speaking frames of his animation, and the latter rendered in-engine and converted
                    into a .png to comply with the rules of the jam (a 3D environment probably would not have been allowed). The character then had its sprite material altered in-engine with a material blueprint: the flat sprite had
                    its colour multiplied by a noise texture hooked up to various user-controlled parameters, enabling the texture to appear to warble at runtime and to dissolve the character in and out at the end of every run.
                    <br />
                    <div style={{display: "flex", alignItems:"center", justifyContent:"center"}}>
                        <img src={JusticeMaterialBlueprint} alt="A screenshot from Unreal Engine 5, showing a blueprint controlling the hologram effect on the main character." style={{width: "100%", alignItems:"center", paddingTop: "20px", paddingBottom:"20px"}} />
                    </div>
                    I used FMOD as the audio solution for the project as with my previous Unreal jam effort, <a href="https://www.harrisongowland.co.uk/games/you-do-it-then"><i>You Do It Then (If You're So Smart)</i></a>; this project avoided the issue with the previous
                    one as it did not use sequences to control the dialogue. The audio clips themselves were recorded in Adobe Audition, where an FFT filter was applied to make it sound like the voice was coming through a telephone, before the clips were converted into FMOD
                    events to be broadcast through an audio channel so the volume could be set by the end user in game. The events themselves were attached to dialogue through data tables, which list the dialogue lines, the FMOD events they are connected to, the length of the audio
                    and sometimes what set of choices to go to next.
                    <br /><br />
                    I was relatively happy with how the game turned out given the serious restrictions on content. The only thing I might change would be, given I undershot the 1000 word limit by a sufficient margin, adding a dialogue tree if the player presses 9 in the
                    introductory dialogue sequence, though in the circumstances given the short turnaround time it's probably not the end of the world. I consider this project relatively complete and self-contained and probably won't revisit it (though if I were to I can't
                    help feeling adding a boss battle in there somewhere might be funny). 
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default InterestsOfJustice; 