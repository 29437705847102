import '../App.css';
import GamePageHeaderImage from "./pageutils";

function TinHearts() {
    return (
        <div>
            <GamePageHeaderImage showOverlay="false" position="center" overlayImage="https://media.wiredproductions.com/wp-content/uploads/2021/08/TH_Logo_Long_Plate-1.png" overlayImageAlt="The Tin Hearts logo" image="https://media.wiredproductions.com/wp-content/uploads/2021/08/TH_Keyart_16x9_1080p_NoLogo-1.jpg" alt="Key art for Tin Hearts"/>
            <div className="GamePageBody" >
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/H1iSm9Q07Xc" title="The Heart of it: The Making of Tin Hearts | Episode 1 &#39;What is Tin Hearts?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br />
                    <i>Tin Hearts</i> is a first person puzzle adventure game and the debut title for the studio I work for, <a href="https://roguesun.com">Rogue Sun</a>. The game is not dissimilar to Lemmings; in Tin Hearts, you guide a troop of toy soldiers
                    to a goal using a variety of game mechanics: balloons for flying, trampolines for bouncing, prisms for changing direction and so on. The gameplay is a framing device that conveys a wider story of a Victorian toy inventor, and the fate of him and his family.
                    <br /><br />
                    The game won <a href="https://www.roguesun.com/dev-blog/tin-hearts-wins-most-anticipated-nintendo-switch-game-award-at-gamescom-2022">Most Anticipated Nintendo Switch game at Gamescom</a> in 2022. It is now out on all platforms, and a VR release is planned for later this year. Tin Hearts was generally critically well-received:
                    <br />
                    <img style={{width: "100%", paddingTop: "10px"}} src="https://i.gyazo.com/f1d65894e613d35ac21b447cfd783223.png" alt="A screenshot from Tin Hearts' Nintendo Switch metacritic page. It has a critical review score of 81 and a user score of 8.8."></img>
                </p>
                <p>
                    <b>What did I do?</b><br />
                    I have been a full-time designer for Rogue Sun on this project for two years, and I was promoted from a junior to a mid-level designer in November 2022. Tin Hearts is developed in the Unity engine.
                    <br /><br />
                    As part of this role I undertake a variety of design responsibilities; for the Tin Hearts project I was mostly responsible for building levels (particularly in the game's third act) which involved
                    conceptualising and implementing innovative puzzles on a sliding scale of difficulty, generating in-level systems using our Blueprints-style visual programming software Playmaker, and liaising with
                    an interdisciplinary team when external support was required, particularly in the art and code departments when design requirements outstripped capacity.
                    <br /><br />
                    I have also been keenly involved in the quality assurance process, being responsible for reviewing the level and puzzle design work of other designers and approving it before it can be merged into the
                    central repository. This involved meeting with my colleagues regularly and iterating on creative briefs and providing feedback.
                    <br /><br />
                    I also designed a number of the game's cutscenes for telegraphing key puzzle moments using Cinemachine and Unity Animator.
                    <br /><br />
                    You can <a href="https://store.steampowered.com/app/1831700/Tin_Hearts/">view the game on Steam</a>. Wired Productions, the game's publisher, also keeps a variety of press assets relating to Tin Hearts on
                    their website, which <a href="https://media.wiredproductions.com/games/tin-hearts/">you can view here</a>.
                </p>
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/vjVRFEEwbNU" title="Tin Hearts | Xbox Gameplay Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>Tin Hearts &copy; Rogue Sun LTD 2023.</p>
                <hr />
            </div>
        </div>
    )
}

export default TinHearts;