import '../App.css';
import GamePageHeaderImage from "./pageutils";

function Solihull() {
    return (
        <div>
            <GamePageHeaderImage image="https://img.itch.zone/aW1nLzU4NjM4MDkucG5n/original/DwOtV6.png" position="100% 70%" alt="The shot from the title screen of P6: Solihull of the protagonist on a bus." />
            <div className="GamePageBody">
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/fSpwSW6H_0U" title="I Made a 3D Game In Four Days" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br />
                    P6: Solihull is a turn-based RPG battle game that I built in Unity over four days in 2021. It follows the story of Leonard, who travels to the town of Solihull, UK in search of an ordinary life, but has his plans turned upside down by an altercation
                    with the fearsome <b style={{color:"red"}}>Greg</b>, pictured below, who challenges him to a fight in the middle of the street. The game can be downloaded from <a href="https://harrisongowland.itch.io/p6-solihull">my itch.io page here</a>.
                    <img style={{marginTop: "10px", marginBottom: "10px" }} src="https://img.itch.zone/aW1hZ2UvMTAyODAxMi81ODYzNzkwLmpwZw==/original/ngrviC.jpg" alt="A shot of Greg, fists raised, in the middle of an empty street at night."></img>
                    The gameplay consists of an opening cutscene, then a fight with Greg in a turn-based combat system involving the use of regular attacks (and magic attacks that cost SP, a kind of mana), items to restore health and energy, and special moves that cause
                    status effects. For instance, Greg is able to cast a spell that poisons the player and deals damage every turn. The first person to remove all their opponent's health wins, and if the player defeats Greg, they view an end cutscene which details the fate
                    of Leonard in Solihull.<br /><br />
                    <b>What did I do?</b><br />
                    I made the game as a portfolio piece and as a project for my YouTube channel, in pursuit of trying more AI-based projects and because my friend had tweeted <a href="https://twitter.com/danahern96/status/1387847089930096641">a shower thought that inspired me to produce it</a>. 
                    It is fully voice acted by me (bad).<br/><br/>
                    The cinematics in the opening and ending are controlled by an XNode graph, which instructs the camera where to go and the system what audio to play, and the wobbly shakycam effect is provided by Cinemachine's perlin noise.<br/><br/>
                    The AI for Greg is hardcoded into a C# script as he is the only enemy in the game. His code includes balancing features such as preventing the enemy from reusing the poison effect if the player has used the antidote in their last turn, and requiring the enemy to use their weakest
                    attack at least once every approximately four turns - this largely alleviated, at least as far as I could tell, the problem where the AI would curb-stomp the player most of the time.<br/><br/>
                    If I were to remake P6: Solihull (and I've thought about it) I would probably make the logic governing Greg's behaviour more generic and customise it inside of a scriptable object, or perhaps use a behaviour tree instead.
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default Solihull; 