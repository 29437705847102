import KetchupMazeImg from '../images/ketchupmaze.png';
import '../App.css';
import GamePageHeaderImage from "./pageutils";
import React, { useState } from "react";

function KetchupMaze() {
    const [showPicture, setShowPicture] = useState(0);
    const displayPicture = () => {
        switch (showPicture) {
            default:
                return null;
            case 0:
                return null; 
            case 1:
                return <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={KetchupMazeImg} alt="A badly drawn maze that runs from start in the top left to a ketchup bottle in the bottom right"></img>
                </div>
        }
    }
    return (
        <div>
            <GamePageHeaderImage image={KetchupMazeImg} objectFit="contain" alt="A badly drawn maze that runs from start in the top left to a ketchup bottle in the bottom right" />
            <div className="GamePageBody">
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/He1AOm4ZMX4" title="Ketchup Maze IMPOSSIBLE SPEEDRUN? NOT ALLOWABLE BY SCIENCE?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br />
                    <i>Ketchup Maze</i> is a maze drawn for the <a href="https://itch.io/jam/lqgj6">Low Quality Game Jam</a>. It is a .png file downloadable from <a href="https://harrisongowland.itch.io/impossible-ketchup-maze">its Itch.io page</a>. It was
                    drawn in Paint at 3AM in about thirty seconds.<br /><br />
                    The objective is to get from the start part of the maze to the ketchup at the end without crossing any of the black lines (thus meeting the ketchup theme of the jam). It is extremely difficult and cannot be done unless you have significant maze experience.<br /><br />
                    <b>What did I do?</b><br />
                    I drew it<br /><br />
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="jujButton" onClick={() => setShowPicture(showPicture === 1 ? 0 : 1)}>{showPicture ===     1 ? "Don't play in browser" : "Play in browser"}</button>
                    </div>

                    {displayPicture()}
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default KetchupMaze; 