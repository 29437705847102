import '../App.css';

function GamePageHeaderImage(props){
    return (
        <div>
            <div style={{height: "300px", width: "100%", filter: "drop-shadow(5px 5px 5px #222)", display: "flex", justifyContent: "center", backgroundPosition: props.position, backgroundSize : props.objectFit, backgroundRepeat: props.repeat, backgroundImage: "url(" + props.image + ")", marginBottom: "30px" }} alt={props.alt}>
                {
                    props.showOverlay ? <img src={props.overlayImage} alt={props.overlayImageAlt}></img> : null
                }
            </div>
        </div>
    )
}

export default GamePageHeaderImage;