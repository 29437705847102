import React, { useState } from "react";
import '../App.css';
import GamePageHeaderImage from "./pageutils";

function RalphCode() {
    return (
        <div style={{ width: "100%" }}>
            <pre id="ralphCode"><code className="hljs language-csharp">
                {`public List<Vector2> CalculateRaycast(List<Vector2> positions, Vector3 position, bool showLine)
{
    positions.Clear(); 
    positions.Add(transform.position); 
    //maxStepDistance is a global: the number of bounces into the future we should show the player, i.e. how many reflections
    //to show the player getting before the trajectory line renderer fades out

    int stepDistance = maxStepDistance;
    RaycastHit hit;
                        
    if (Physics.Raycast(transform.position, transform.TransformDirection(Vector3.forward), out hit, Mathf.Infinity))
    {
        //incomingVec is the line going from the players current position to the cursor; outgoingVec is the vector direction
        //the player would travel in if they cleanly bounced off of the surface theyve hit
        Vector3 incomingVec = new Vector3(hit.point.x, 0.5f, hit.point.z) - transform.position;
        Vector3 outgoingVec = Vector3.Reflect(incomingVec, hit.normal);
                        
        Debug.Log("Hit something: " + hit.collider.gameObject.name);
                            
        if (hit.collider.gameObject.tag == "Node")
        {
            stepDistance = 0;
            positions.Add(hit.point);
            positions.Add(hit.collider.transform.position);
        }
        else
        {
            if (showLine)
            {
                stepDistance--;
            }
            positions.Add(new Vector3(hit.point.x, 0.5f, hit.point.z)); 
        } 
        if (stepDistance > 0) 
        {
            return CalculateRaycast(positions, hit, stepDistance, outgoingVec, showLine); 
        } 
        else 
        { 
            if (showLine) 
            { 
                DrawLineRenderer._renderer.DrawLine(positions); 
            } 
            return positions; 
        } 
        else 
        { 
            Debug.LogError("Something went very badly wrong"); 
            return positions; 
        } 
    }
}`}</code></pre></div>
    )
}

function RalphAdventure() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    }
    return (
        <div>
            <GamePageHeaderImage image="https://img.itch.zone/aW1nLzc1OTU4NTQucG5n/original/4Rr8u%2F.png" position="center" objectFit="contain" repeat="repeat-x" alt="Key art for Ralph's Ridiculous Adventure"/>
            <div className="GamePageBody" >
                <div class="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/Wi3615xZMWs" title="Ralph&#39;s RIdiculous Adventure playthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p><b>What is it?</b><br />
                    <i>Ralph's Ridiculous Adventure</i> is a top-down action and puzzle game built in Unity and submitted as part of a game jam organised by the production team behind
                    Channel 4's Gamesmaster. It tells the story of Ralph and his pet snake, Mr Slithers, as they try to rescue a community of people that have been turned into cows by
                    a sinister intergalactic force. The game was made in 72 hours and can be <a href="https://harrisongowland.itch.io/ralphs-ridiculous-adventure">downloaded on my Itch
                        page</a> (or from <a href="https://danreha.itch.io/ralphs-ridiculous-adventure">the mirror on Dan Ahern's page</a>).
                    <br /><br />
                    The gameplay loop involves the player, Ralph, riding Mr Slithers between a number of magic pots strewn about the level. The snake can crash into enemies to defeat them,
                    or into contextual puzzle elements to solve problems; for instance, in one level the player hits a football to get it to fly into a goal in order to distract the enemy
                    pigs and rescue some cows. (It makes sense in the moment I swear)
                    <br /><br />
                    <img src="https://img.itch.zone/aW1hZ2UvMTMwNTc3Mi83NTk1NzEwLnBuZw==/original/b6gt7o.png" alt="A screenshot from Ralph's Ridiculous Adventure. It depicts Mr Slithers about to hit a football through a goal guarded by an evil pig."></img>
                    <br /><br />
                    <b>What did I do?</b><br />
                    I was a C# coder and systems designer on the project. Among my contributions was the develompent of the movement system, i.e. the snake bouncing from one pot to another and
                    neatly pinging off of walls and geometry:<br />
                    <hr />
                    <span className="hoverDropdown" onClick={() => handleOpen()}>{open ? "⮝ Hide code" : "⮟ Show code"}</span>
                    {open ? <RalphCode /> : null}<br />
                    <hr />
                    I also got to design a lot of the gameplay, including the machine self-destruct level, in which the player matches cards, and the pot memory game in which the player has to memorise
                    the cow's potion request and deliver it to them by jumping over the correct letters (a game that is iterated on with a difficulty increase in the third act).
                    <img src="https://img.itch.zone/aW1hZ2UvMTMwNTc3Mi83NTk1NzIzLnBuZw==/original/oWFaK1.png" alt="A screenshot from Ralph's Ridiculous Adventure. It depicts Mr Slithers the snake about to jump from one pot to another. The cows in the top left are saying 'cba'."></img>
                    <br />
                    I got to work with a small multidisciplinary team, the Lovely Assistants, which was put together by myself and the production team, including 2D artist <a href="https://twitter.com/BonsaiTreehouse/">@BonsaiTreehouse</a>.
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default RalphAdventure;