import '../App.css';
import GamePageHeaderImage from "./pageutils";
import PresidentCabbageImage from "../images/president_cabbage.jpg";

function PresidentCabbage() {
    return (
        <div>
            <GamePageHeaderImage image={PresidentCabbageImage} objectFit="contain" position="35% right" alt="Harrison Gowland giving an interview about President Cabbage"/>
            <div className="GamePageBody">
                <p>
                    <b>What is it?</b><br />
                    <i>President Cabbage</i> (or, <i>President Cabbage: A Thrilling Biopic of a Nation in Crisis</i>) is a ~55,000 word novella I self-published in 2018. It follows the story of Herschel Cabbage,
                    a garden-variety cabbage, who in a shock electoral upset becomes the President of the United States. The plot mostly deals with President Cabbage's response to various geopolitical crises, as well as the
                    politicking of his cabinet and wider government as they deal with the ramifications of a cabbage at the nuclear button. It can be bought and previewed on Amazon at the link below.<br /><br />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <iframe title="President Cabbage" type="text/html" sandbox="allow-scripts allow-same-origin allow-popups" width="336" height="550" frameborder="0" allowfullscreen style={{ maxWidth: "100%" }} src="https://read.amazon.co.uk/kp/card?asin=B07G1NSJJN&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_ZX2FYBAT7ZXB04JBY1YC" ></iframe>
                    </div>
                </p>
            </div>
        </div>
    )
}

export default PresidentCabbage; 