import GamePageHeaderImage from "./pageutils";
import LastMinuteGallery from '../images/last-minute-gallery.jpg';
import GalleryFeedback from '../images/gallery-feedback.png';

function Gallery() {
    return (
        <div>
            <GamePageHeaderImage image="https://img.itch.zone/aW1hZ2UvMTcyOTUwMC8xMDE4MzkyNy5qcGc=/original/qjV3du.jpg" alt="A shot from The Last Minute Gallery, including dialogue from the opening cutscene" position="right 35% bottom 10%"></GamePageHeaderImage>
            <div className="GamePageBody">
                <div className="videoContainer">
                    <iframe width="952" height="526" src="https://www.youtube.com/embed/ICrKzNWiNUU" title="The Last Minute Gallery playthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p>
                    <b>What is it?</b><br />
                    <i>The Last Minute Gallery</i> is a short drawing game I made in Unity for Ludum Dare. The game follows the story of the director of a new independent art gallery, who is shocked to discover that their delivery of indie paintings has been delayed indefinitely.
                    This is compounded by the fact that a crowd of journalists are coming in short order to review the new gallery. The director decides to create an array of paintings themselves in order to disguise the lack of artwork.<br/><br/>
                    The gameplay loop involves the player being given a prompt for a new painting, and then getting ten seconds (the game jam theme was "every ten seconds") to paint it before moving on to the next. This cycle repeats until all the paintings have been filled in -
                    at this point, the gallery is updated with the paintings the player has creaetd, and the fate of the gallery is revealed.<br/><br/>
                    The game can be <a href="https://harrisongowland.itch.io/the-last-minute-gallery">downloaded on my itch page</a>.<br />
                    <img style={{marginTop:"10px", marginBottom:"10px"}} src={LastMinuteGallery} alt="A shot of a completed gallery with user-generated paintings in The Last Minute Gallery"></img>
                    <b>What did I do?</b><br/>
                    I developed the game in 2022 as <a href="https://ldjam.com/events/ludum-dare/51/the-last-minute-gallery">a solo entry for the compo category of the 51st Ludum Dare game jam</a>. Per the rules of the category, I made all of the assets and code in <i>The Last Minute Gallery</i>
                    during the course of the jam, including the <a href="https://soundcloud.com/harrisongowland/sets/the-last-minute-gallery">music</a> (which reviewed as well as I'm sure you can imagine) and sound effects.<br/><br />
                    This was the first game I made that featured live texture editing, enabling the player to draw rudimentary images on a canvas and then save the result so that it could be viewed in the gallery review section later.<br/><br/>
                    The game was received very well: it came third in the humour category in the compo and sixth in that category out of all 2420 entries.
                    <img style={{marginTop:"10px", marginBottom:"10px"}} src={GalleryFeedback} alt="A series of effusive comments about the game on the Ludum Dare website, mostly discussing its comic value and wacky nature"></img>
                    Were I to remake the game, I would probably tween the pencil to the player's mouse position rather than use the player's mouse position directly to decide where to draw pixels on the canvas, as the existing implementation makes drawing fast strokes look a bit spotty. I would
                    probably also reduce the number of paintings required - in retrospect I think the core loop outstays its welcome slightly. For the 48 hours available to develop it, though, I'm happy with how it turned out.<br /><br />
                    You can view <a href="https://github.com/harrisongowland/the-last-minute-gallery">the Github repository containing the code here</a>.
                    <img style={{marginTop:"10px", marginBottom:"10px"}} src="https://img.itch.zone/aW1hZ2UvMTcyOTUwMC8xMDE4Mzk2My5qcGc=/original/4NhHfB.jpg" alt="An example drawing in The Last Minute Gallery, using the prompt 'the horrors of technology'. The drawing depicts a scared face looking at a phone."></img>
                    <hr ></hr>
                </p>
            </div>
        </div>
    )
}

export default Gallery; 