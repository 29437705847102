import '../App.css';
import GamePageHeaderImage from "./pageutils";
import ParallaxSC from '../images/Parallax2.jpg';
import Notepad from '../images/parallax-notepad.png';

function Parallax(){
    return (
        <div>
            <GamePageHeaderImage image={ParallaxSC} position="right 35% bottom 30%" alt="A pixel art screenshot from Parallax, with the two protagonists standing next to a Desoto Adventurer." />
            <div className="GamePageBody">
                <iframe width="952" height="528" src="https://www.youtube.com/embed/s5X3ygMMsOw" title="Parallax playthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <p>
                    <b>What is it?</b><br/>
                    <i>Parallax</i> is a 2D point and click adventure game I developed with my friend <a href="https://www.linkedin.com/in/matthew-johnson-814834186/">Matthew Johnson</a> for the <a href="https://itch.io/jam/uop-gamejam-2020">University of Portsmouth game jam</a> in 2020.
                    It tells the story of two archivists, Yolande and Ford, who travel to the mysterious abandoned village of Bockhampton which is bizarrely intact after a devastating war wipes out most of the world. They soon discover that more serious, and supernatural, forces are at work.
                    The game took honourable mention for best game at the game jam's award ceremony and is <a href="https://harrisongowland.itch.io/parallax">available for download on my Itch.io page</a>.<br/><br/>
                    The gameplay loop involves moving around the town and clicking on things to investigate them. Some objects, when investigated, are added to the player's notepad. Additionally, the protagonist may have thoughts about some of the things they find - these thoughts are also added
                    to the notepad. When the player identifies a logical connectino between two items or thoughts, they can combine them in the notepad to generate a new thought, item or lead, allowing them to make progress through the story. This system is inspired by and based on the logic system in
                    the Miles Edgeworth Investigations series, which functions on <a href="https://aceattorney.fandom.com/wiki/Logic">a similar premise</a>.
                    <img src={Notepad} style={{width: "100%", paddingTop: "20px", paddingBottom:"20px"}} alt="A screenshot from Parallax, displaying the notepad."></img>
                    <br/><br/>
                    <b>What did I do?</b><br/>
                    I was more involved with the artistic and narrative design of this game as it was built by Matthew in GameMaker Studio 2, which was a welcome change as it gave me an opportunity to do more pixel art. This also gave me wide latitude to make decisions about the environmental design and some
                    of the logic puzzles, for instance the section with the coat that was thrown on the roof near the town hall.<br/><br/>
                    We also shared responsibility for crafting the narrative, as well as generating the eleventh hour twist that the protagonist is trapped in a town looping on itself. Spoilers I guess but <a href="https://www.youtube.com/watch?v=dinyOvO2EEo">it came out in 2020 so</a>
                    <hr/>
                </p>
            </div>
        </div>
    )
}

export default Parallax; 