import '../App.css';
import GamePageHeaderImage from "./pageutils";

function SecretProject(){
    return (
        <div>
            <GamePageHeaderImage image="https://images.pexels.com/photos/874242/pexels-photo-874242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" position="right 35% bottom 75%" alt="A photograph of a confused man using a laptop"/>
            <div className="GamePageBody">
                <p>
                    <b>What is it?</b><br></br>
                    The secret project is, as the name implies, tragically quite secret, making it not really possible for me to describe it in any real detail. I have been on loan to <a href="https://www.flamingfowlstudios.com/">Flaming Fowl Studios</a> to provide tech support on it.
                    <br />
                    <b>What did I do?</b>
                    <br />
                    Again, I can't really go into much detail without getting into trouble. Most of my work revolves around building Unreal blueprints, a sentence so light on detail I might as well have said nothing. 
                </p>
            </div>
        </div>
    )
}

export default SecretProject; 