import '../App.css';
import GamePageHeaderImage from "./pageutils";

function CraneOdyssey(){
    return (
        <div>
            <GamePageHeaderImage image="https://img.itch.zone/aW1nLzEwMTY4MjYzLnBuZw==/315x250%23c/kmtIxM.png" objectFit="contain" alt="The key art for Crane's Odyssey"/>
            <div className="GamePageBody">
                <p>
                    <b>What is it?</b><br/>
                    <i>Crane's Odyssey</i> is a visual novel I made for the <a href="https://itch.io/jam/interactive-story-jam-2/rate/1726604">second Interactive Story Jam operated by Spinoff</a>, an app for making interactive stories. You can play it <a href="https://harrisongowland.co.uk/play-crane-odyssey.html">on this website here</a>.
                    (Note that the game contains a bug that means content warnings aren't displayed; contents warnings for Crane's Odyssey are firearms, violence at infrequent intervals, and major character death. I regret the error.)<br/><br/>
                    The story follows Antony Crane, an ordinary man who's husband, Lucas, went missing in unusual circumstances - and, after a private detective he hired tracks him to a warehouse on the edge of town, Crane's resulting investigation takes him on a journey into another dimension and through his own memories, recontextualising his
                    marriage and possibly altering the world forever.<br/>
                    <div style={{display: "flex", alignItems:"center", justifyContent:"center"}}>
                        <img src="https://img.itch.zone/aW1hZ2UvMTcyNjYwNC8xMDE3MTU3Ny5qcGc=/original/DXCo8E.jpg" alt="A screenshot from Crane's Odyssey featuring the secondary protagonist speaking" style={{width: "30%", alignItems:"center", paddingTop: "20px", paddingBottom:"20px"}} />
                    </div>
                    The story is based on a strategy RPG draft and takes inspiration from the Backrooms for the liminal space theming; it survives a previous, unfinished version, in which the protagonist Antony Crane is a queer chess grandmaster attempting to navigate his feelings for his opponent competing for the world championship.<br/><br/>
                    The gameplay loop primarily consists of Crane moving from place to place and interrogating the people he meets, gathering information about the mysterious other world and about the disappearance of his partner. It takes conservatively about ten minutes to play through completely.<br/><br/>
                    I created the character models in Reallusion's Character Creator 3 and rendered out 2D images of them before placing them in Unity scenes to match the lighting of the environment. There are about one hundred such images that make up the entire project. <a href="https://drive.google.com/drive/folders/1MfwzJ1FJywePKVhOyXVWgV9e9mQjmN4i?usp=sharing">
                    They can be viewed here</a> (note that because these images cover the span of the game, there are unmarked spoilers for the ending). 
                    <hr />
                </p>
            </div>
        </div>
    )
}

export default CraneOdyssey;